import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AuthButton from 'components/AuthButton/AuthButton';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadFileByUrl } from 'shared/utils/functions';
import { getTokenFromStorage } from 'shared/utils/getTokenFromStorage';
import CalendarIcon from '../../../../shared/icons/CalendarIcon';
import FlowerCheckIcon from '../../../../shared/icons/FlowerCheckIcon';
import QuestionIcon from '../../../../shared/icons/QuestionIcon';
import { useFetchOrganisationListQuery } from '../../../../store/hooks/general';
import {
  useLazyGetServiceDetailQuery,
  useGetServiceDetailQuery,
} from '../../../../store/hooks/services';
import { moduleRoutes } from '../helpers/constants';
import { variants } from './helpers/constants';

const ServiceDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { serviceId } = useParams();

  const [refetch, serviceRes] = useLazyGetServiceDetailQuery();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  useEffect(() => {
    if (serviceId) {
      refetch(serviceId);
    }
  }, [serviceId]);

  const details = serviceRes.data;

  return (
    <Box sx={{ pt: 4 }}>
      <Box
        sx={{
          borderRadius: '40px',
          background: '#fff',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: isSmallDevice ? 'column' : 'row',
            gap: 2,
            mb: 3,
            p: 4,
            pb: 1,
          }}
        >
          <Button
            onClick={() => {
              navigate('/' + moduleRoutes[5] + serviceId + '/application');
            }}
            color="primary"
            variant="contained"
            sx={{
              fontSize: isSmallDevice ? '12px' : '18px',
              padding: isSmallDevice ? '7px 12px' : 'auto',
            }}
          >
            Перейти в реестр
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: isSmallDevice ? '12px' : '18px',
              padding: isSmallDevice ? '7px 12px' : 'auto',
            }}
          >
            Информация
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              fontSize: isSmallDevice ? '12px' : '18px',
              padding: isSmallDevice ? '7px 12px' : 'auto',
            }}
          >
            Оплата
          </Button> */}
        </Box>

        <Box
          className="flex flex-col md:flex-row"
          sx={{
            display: 'flex',
            flexDirection: isSmallDevice ? 'column' : 'row',
            gap: 4,
            p: 4,
          }}
        >
          <Box sx={{ flex: 2 }}>
            <Box
              sx={{
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
                borderRadius: 4,
                py: 4,
                mb: 3,
                backgroundColor: '#fff',
              }}
            >
              <img
                className="tw-max-w-[200px] tw-mx-auto tw-mb-[90px]"
                src="/assets/images/gerb.png"
              />
              <Typography
                sx={{ px: 4, fontSize: isSmallDevice ? '14px' : '32px' }}
                color="primary"
                textAlign="center"
                variant="h4"
              >
                {details?.name}
              </Typography>
            </Box>

            <>
              {' '}
              Цена обработки заявления :{' '}
              <b>
                {' '}
                {details?.price} <br></br>{' '}
              </b>
              Цена услуги : <b>{details?.treatmentPrice} </b>
            </>

            {getTokenFromStorage() && serviceId ? (
              <AuthButton serviceId={serviceId} />
            ) : (
              <Button
                size="large"
                sx={{
                  height: 48,
                  borderWidth: 2,
                  fontSize: isSmallDevice ? '12px' : '25px',
                }}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/auth/login');
                }}
              >
                Получить услугу
              </Button>
            )}
          </Box>
          <Box sx={{ flex: 4 }}>
            <Typography
              sx={{
                mb: 4,
                fontSize: isSmallDevice ? '18px' : '32px',
                fontWeight: 500,
              }}
              variant="h4"
            >
              Для чего нужен данный документ ?
            </Typography>

            <Typography
              variant="body1"
              fontSize={16}
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {details?.description
                .split('')
                .map((el) => {
                  if (el === ' ') {
                    return '\t';
                  }
                  return el;
                })
                .join('')}
            </Typography>
          </Box>
        </Box>

        {/* <Box
          sx={{
            display: 'flex',
            gap: 4,
            px: isSmallDevice ? 2 : 4,
            py: 3,
            mb: 3,
            border: `1px solid ${theme.palette.primary.main}`,
            borderLeft: 'none',
            borderRight: 'none',
            flexDirection: isSmallDevice ? 'column' : 'row',
            '& .MuiButtonBase-root': {
              boxSizing: 'border-box !important',
            },
          }}
        >
          <Button
            startIcon={<FlowerCheckIcon />}
            variant="outlined"
            color="primary"
            sx={{
              borderWidth: 0,
              height: '45px',
              minWidth: isSmallDevice ? '229px' : '320px',
              fontSize: isSmallDevice ? '12px' : '18px',
              justifyContent: isSmallDevice ? 'flex-start' : 'center',
            }}
          >
            Необходимые документы
          </Button> */}
        {/* <Button
            startIcon={<CalendarIcon />}
            variant="outlined"
            color="primary"
            sx={{
              borderWidth: 0,
              height: '45px',
              minWidth: isSmallDevice ? '229px' : '240px',
              fontSize: isSmallDevice ? '12px' : '18px',
              justifyContent: isSmallDevice ? 'flex-start' : 'center',
            }}
          >
            Срок подготовки
          </Button>
          <Button
            startIcon={<QuestionIcon />}
            variant="outlined"
            color="primary"
            sx={{
              borderWidth: 0,
              height: '45px',
              minWidth: isSmallDevice ? '241px' : '342px',
              fontSize: isSmallDevice ? '12px' : '18px',
              justifyContent: isSmallDevice ? 'flex-start' : 'center',
            }}
          >
            Часто задаваемые вопросы
          </Button> */}
        {/* </Box> */}

        <Box sx={{ p: 4, pt: 2 }}>
          <ul style={{ marginBottom: '20px' }}>
            {details?.files?.map((item) => (
              <li key={item.id} className="tw-mb-3">
                <span className="tw-inline-block tw-w-[12px] tw-h-[12px] tw-rounded-[6px] tw-bg-[#607D8B] tw-text-1.5xl tw-mr-4 tw-mt-[3px]"></span>
                {item.name}
                {item.template ? (
                  <Button
                    sx={{
                      fontSize: '12px',
                      textTransform: 'none',
                      ml: 2,
                    }}
                    onClick={() => downloadFileByUrl(item?.template || '')}
                    variant="text"
                    size="small"
                  >
                    Скачать
                  </Button>
                ) : (
                  ''
                )}
              </li>
            ))}
          </ul>

          <Typography
            color="primary"
            variant="h4"
            sx={{ mb: 2, fontSize: isSmallDevice ? '18px' : '38px' }}
          >
            Как получить услугу ?
          </Typography>

          <ol style={{ marginBottom: '20px' }}>
            {variants.map((item, idx) => (
              <li key={item} className="tw-mb-3">
                <span className="tw-text-1.5xl tw-mr-4">{idx + 1}. </span>
                {item}
              </li>
            ))}
          </ol>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceDetails;
