import {t} from 'i18next';

export const questions = [
  'Свидетельство о государственной регистрации',
  'Свидетельство о праве на осуществление  деятелности',
  'Выписка из единого государственного реестра юридических лиц ',
  'Выписка из единого государственного реестра индивидуальной предпринимателей',
  'Заключенный договор',
  'Справка определяющая источник дохода',
  'Документ, определяющий источник доходов из иностранного государства',
  'Паспорт гражданина Республики Таджикистана',
  'Чек оплаты за услугу <<Документ подтверждающий статуса резидента РТ>>',
];

export const variants = [
  t('HomePage.checkDocuments'),
  t('HomePage.makePayment'),
  t('HomePage.clickButton'),
  t('HomePage.trackStatus'),
];
