import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEY, Language } from 'locales';
import './langBtn.css';

const langs: any = {
  ru: 'Рус',
  en: 'Eng',
  tj: 'Тоҷ',
};

function LangBtn() {
  const [languageButtons, setLanguageButtons] = useState(false);
  const [languageBtn, setLanguageBtn] = useState(false);

  const { i18n } = useTranslation();

  const [value, setValue] = useState(
    localStorage.getItem(LANGUAGE_KEY) || 'ru'
  );

  const handleChange = (val: string) => {
    localStorage.setItem(LANGUAGE_KEY, val);
    i18n.changeLanguage(val);
    setValue(val);
  };

  useEffect(() => {
    const lang = localStorage.getItem(LANGUAGE_KEY) || Language.RU;
    i18n.changeLanguage(lang);
  }, [i18n]);
  return (
    <div className="tw-fixed tw-right-[64px] tw-bottom-[27px] tw-text-[#007B9E] tw-text-[18px] tw-leading-[22px] tw-font-[600] tw-w-[54px] tw-h-auto mdMUI:tw-flex tw-hidden tw-flex-col tw-justify-between tw-z-20">
      {languageBtn ? (
        <>
          <button
            onClick={() => {
              setLanguageButtons(!languageButtons);
              handleChange('tj');
            }}
            className={`${
              languageButtons ? 'languageBtn3' : 'languageBtn3Close'
            } tw-w-[54px] tw-h-[54px] tw-my-[8px] tw-rounded-full tw-bg-[#F7F8F9] tw-shadow-[-1px_4px_6px] tw-shadow-[rgba(154,154,154,0.25)] hover:tw-shadow-[rgba(154,154,154,0.75)]`}
          >
            Тоҷ
          </button>
          <button
            onClick={() => {
              setLanguageButtons(!languageButtons);
              handleChange('ru');
            }}
            className={`${
              languageButtons ? 'languageBtn2' : 'languageBtn2Close'
            } tw-w-[54px] tw-h-[54px] tw-my-[8px] tw-rounded-full tw-bg-[#F7F8F9] tw-shadow-[-1px_4px_6px] tw-shadow-[rgba(154,154,154,0.25)] hover:tw-shadow-[rgba(154,154,154,0.75)]`}
          >
            Рус
          </button>
          <button
            onClick={() => {
              setLanguageButtons(!languageButtons);
              handleChange('en');
            }}
            className={`${
              languageButtons ? 'languageBtn1' : 'languageBtn1Close'
            } tw-w-[54px] tw-h-[54px] tw-my-[8px] tw-rounded-full tw-bg-[#F7F8F9] tw-shadow-[-1px_4px_6px] tw-shadow-[rgba(154,154,154,0.25)] hover:tw-shadow-[rgba(154,154,154,0.75)]`}
          >
            Eng
          </button>
        </>
      ) : null}
      <button
        onClick={() => {
          setLanguageButtons(!languageButtons);
          setLanguageBtn(true);
        }}
        className="tw-z-10 tw-w-[54px] tw-h-[54px] tw-my-[8px] tw-text-center tw-rounded-full tw-bg-[#F7F8F9] tw-shadow-[-1px_4px_6px] tw-shadow-[rgba(154,154,154,0.25)] hover:tw-shadow-[rgba(154,154,154,0.75)]"
      >
        {languageButtons ? (
          <img
            src="/images/img_arrow_right_gray_900_1.svg"
            alt="Arrowright"
            className="tw-h-[18px] tw-m-auto"
          />
        ) : (
          langs[value]
        )}
      </button>
    </div>
  );
}

export default LangBtn;
