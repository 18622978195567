/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  MenuItem,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SectionForm } from 'components/forms/SectionForm';
import DataTable from 'components/MySerivces/DataTable';
import Loading from 'components/ui/Loading';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from 'shared/hooks/useSession';
import FileAddIcon from 'shared/icons/FileAddIcon';
import ListIcon from 'shared/icons/ListIcon';
import { deepCopy } from 'shared/utils/deepCopy';
import { IAdminSearch } from 'store/hooks/services';
import {
  useIsAuthorizedQuery,
  useLazyUserProfileUserSearchQuery,
  useUserProfileUserSearchQuery,
} from 'store/hooks/userprofile';
import { serviceStatusOptions } from '../services/application/create/helpers/constants';
import {
  headerTitles,
  initialFilters,
  userType,
  UserType,
} from './helpers/contants';

import { GridCheckCircleIcon } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';

function Reestr() {
  const [state, setState] = useState<number>(0);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [filters, setFilters] = useState<IAdminSearch['filters']>(
    deepCopy(initialFilters)
  );

  const { pathname } = useLocation();

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data: isAuthorized } = useIsAuthorizedQuery({});
  const { user } = useSession();

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage1(value);
  };

  const [refetch, { isFetching, data: users }] =
    useLazyUserProfileUserSearchQuery();

  const selectedState = useMemo(
    () => serviceStatusOptions?.find((item: any) => item.id === filters.state),
    [filters.state]
  );

  useEffect(() => {
    refetch({
      ids: null,
      orderBy: {
        orderColumn: 1,
        direction: 1,
      },
      pageInfo: {
        pageNumber: isSmallDevice ? page1 : page + 1,
        pageSize: pageSize,
      },
      filters: {
        ...filters,
      },
    });
  }, [page, page1, pageSize]);

  return (
    <>
      <Box sx={{ py: 4 }}>
        <SectionForm emptyBox title={'Реестр'}>
          <div
            className={`${
              isSmallDevice
                ? 'tw-flex tw-flex-col '
                : 'tw-grid  tw-grid-cols-[1fr_187px] tw-w-full tw-gap-4'
            }   tw-mb-10`}
          >
            {isSmallDevice ? (
              <div className="tw-flex tw-flex-col tw-gap-4">
                <TextField
                  label="Логин"
                  size="small"
                  value={filters.login}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      login: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Имя"
                  size="small"
                  value={filters.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      name: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Фамилия"
                  size="small"
                  value={filters.surName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      surName: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Тел"
                  type="number"
                  size="small"
                  value={filters.phone}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      phone: event.target.value,
                    });
                  }}
                />

                <TextField
                  label="Тип пользователя"
                  type="number"
                  size="small"
                  value={filters.userType}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const obj = {
                      id: event.target.value,
                      //@ts-ignore
                      value: UserType[event.target.value],
                    };
                    setFilters({
                      ...filters,
                      userType: obj,
                    });
                  }}
                  select
                >
                  <MenuItem value={'1'}>Физическое лицо</MenuItem>
                  <MenuItem value={'2'}>Юридическое лицо </MenuItem>
                </TextField>
                <Button
                  startIcon={
                    isFetching ? (
                      <Loading />
                    ) : (
                      <ListIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    )
                  }
                  disabled={isFetching}
                  onClick={() => {
                    refetch({
                      ids: null,
                      orderBy: {
                        orderColumn: 1,
                        direction: 1,
                      },
                      pageInfo: {
                        pageNumber: isSmallDevice ? page1 : page + 1,
                        pageSize: pageSize,
                      },
                      filters: {
                        ...filters,
                      },
                    });
                  }}
                >
                  Список
                </Button>
              </div>
            ) : (
              <div className="tw-grid  tw-grid-flow-col-dense  tw-auto-cols-[200px] tw-gap-4">
                <TextField
                  label="Логин"
                  size="small"
                  value={filters.login}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      login: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Имя"
                  size="small"
                  value={filters.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      name: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Фамилия"
                  size="small"
                  value={filters.surName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      surName: event.target.value,
                    });
                  }}
                />
                <TextField
                  label="Тел"
                  type="number"
                  size="small"
                  value={filters.phone}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFilters({
                      ...filters,
                      phone: event.target.value,
                    });
                  }}
                />

                <TextField
                  label="Тип пользователя"
                  type="number"
                  size="small"
                  value={filters.userType}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const obj = {
                      id: event.target.value,
                      //@ts-ignore
                      value: UserType[event.target.value],
                    };
                    setFilters({
                      ...filters,
                      userType: obj,
                    });
                  }}
                  select
                >
                  <MenuItem value={'1'}>Физическое лицо</MenuItem>
                  <MenuItem value={'2'}>Юридическое лицо </MenuItem>
                </TextField>
                <Button
                  startIcon={
                    isFetching ? (
                      <Loading />
                    ) : (
                      <ListIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    )
                  }
                  disabled={isFetching}
                  onClick={() => {
                    refetch({
                      ids: null,
                      orderBy: {
                        orderColumn: 1,
                        direction: 1,
                      },
                      pageInfo: {
                        pageNumber: isSmallDevice ? page1 : page + 1,
                        pageSize: pageSize,
                      },
                      filters: {
                        ...filters,
                      },
                    });
                  }}
                >
                  Список
                </Button>
              </div>
            )}
            {/* <Box
              sx={{
                display: 'flex',
                gap: '20px',
                pt: isSmallDevice ? '20px' : '0',
              }}
            >
              <Link to={'/'}>
                <Button
                  sx={{ display: isSmallDevice ? 'none' : 'flex' }}
                  variant="contained"
                  startIcon={
                    <FileAddIcon
                      width="18px"
                      height="18px"
                      fill="currentColor"
                      stroke="none"
                    />
                  }
                >
                  Добавить
                </Button>
              </Link>
            </Box> */}
          </div>

          {isSmallDevice && (
            <div className="tw-py-4 tw-bg-[white] tw-p-6 tw-rounded-[8px]">
              <div className="tw-grid tw-grid-cols-[1fr_187px] tw-w-full tw-gap-4 tw-mb-10">
                {isSmallDevice ? null : (
                  <div className="tw-grid  tw-grid-flow-col-dense tw-auto-cols-[200px] tw-gap-4">
                    <Autocomplete
                      disablePortal
                      options={serviceStatusOptions}
                      size="small"
                      value={selectedState}
                      getOptionLabel={(option) => option.value as string}
                      renderInput={(params) => (
                        <TextField {...params} label="Статус" />
                      )}
                      onChange={(event, value) => {
                        setFilters({
                          ...filters,
                          state: Number(value?.id) || 0,
                        });
                      }}
                    />

                    <Button
                      startIcon={
                        isFetching ? (
                          <Loading />
                        ) : (
                          <ListIcon
                            width="18px"
                            height="18px"
                            fill="currentColor"
                            stroke="none"
                          />
                        )
                      }
                      disabled={isFetching}
                      onClick={() => {
                        refetch({
                          ids: null,
                          orderBy: {
                            orderColumn: 1,
                            direction: 1,
                          },
                          pageInfo: {
                            pageNumber: isSmallDevice ? page1 : page + 1,
                            pageSize: pageSize,
                          },
                          filters: {
                            ...filters,
                          },
                        });
                      }}
                    >
                      Список
                    </Button>
                  </div>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    pt: isSmallDevice ? '20px' : '0',
                  }}
                >
                  {/* <Button
                    onClick={() => {
                      if (user) {
                        if (isAuthorized) {
                          navigate('/');
                        } else {
                          toast.error('Необходимо пройти аутентификацию.', {
                            autoClose: 5000,
                          });
                          navigate('/profile/account');
                        }
                      }
                    }}
                    variant="contained"
                    startIcon={
                      <FileAddIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    }
                  >
                    Добавить
                  </Button> */}
                </Box>
              </div>
              {isSmallDevice
                ? users?.items?.length > 0 && (
                    <>
                      {users?.items?.map((item: any) => {
                        return (
                          <>
                            <TableCellToRow
                              key={item.id}
                              onClick={() => {
                                navigate('/admin/' + item.id);
                              }}
                            >
                              <TableCellToRowKey>№</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.id}
                              </TableCellToRowValue>
                              <TableCellToRowKey>Имя</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.userName}
                              </TableCellToRowValue>
                              <TableCellToRowKey>login</TableCellToRowKey>
                              <TableCellToRowValue>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                  }}
                                >
                                  <Avatar src={item.user?.avatar} />
                                  <Typography fontSize="15px">
                                    {item.login}
                                  </Typography>
                                </Box>
                              </TableCellToRowValue>
                              <TableCellToRowKey>ИНН</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.inn}
                              </TableCellToRowValue>
                              <TableCellToRowKey>Email</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.email}
                              </TableCellToRowValue>
                              <TableCellToRowKey>Паспорт</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.passportNumber}
                              </TableCellToRowValue>
                              <TableCellToRowKey>
                                Тип пользователя
                              </TableCellToRowKey>
                              <TableCellToRowValue>
                                {
                                  //@ts-ignore
                                  userType[item.userName]
                                }
                              </TableCellToRowValue>
                              <TableCellToRowKey>authorized</TableCellToRowKey>
                              <TableCellToRowValue>
                                {item.authorized ? (
                                  <GridCheckCircleIcon
                                    fontSize="large"
                                    color="success"
                                  />
                                ) : (
                                  <ErrorIcon fontSize="large" color="error" />
                                )}
                              </TableCellToRowValue>
                            </TableCellToRow>
                          </>
                        );
                      })}
                      <Pagination
                        color="primary"
                        siblingCount={0}
                        count={Math.ceil(users?.total / 10)}
                        page={page1}
                        onChange={handleChange}
                      />
                    </>
                  )
                : null}
            </div>
          )}
          {!isSmallDevice && (
            <DataTable
              sx={{ fontSize: '13px' }}
              onRowClick={(params: any) => {
                navigate('/admin/' + params.id);
              }}
              disableSelection
              columns={headerTitles}
              items={users?.items || []}
              isLoading={isFetching}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              totalItems={users?.total}
            />
          )}
        </SectionForm>
      </Box>
    </>
  );
}

export default Reestr;
