import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserprofileDetailsQuery } from 'store/hooks/userprofile';

interface Props {
  className?: string;
  userImage?: string | null;
  addCommentFunc: (param: string) => void;
}

export default function UserAddComment({
  userImage,
  addCommentFunc,
  ...props
}: Props) {
  const { data: profileData, refetch } = useGetUserprofileDetailsQuery();
   const {t} = useTranslation()
  return (
    <div
      {...props}
      className={`${props.className} tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-items-start tw-gap-5`}
    >
      <img
        src={userImage || '/images/img_empty_user.svg'}
        // @ts-ignore
        alt={profileData?.displayName || 'user'}
        className="tw-h-[48px] tw-rounded-[24px] tw-object-cover"
      />
      <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start tw-gap-2.5 md:tw-self-center tw-self-stretch">
        <p className="tw-text-[16px] tw-font-roboto tw-font-medium tw-text-[#66727F]">
          {profileData?.displayName}
        </p>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();

            addCommentFunc(e.target.comment.value);

            e.target.reset();
          }}
          className="tw-w-full tw-flex tw-flex-col tw-items-end tw-space-y-3"
        >
          <input
            name="comment"
            required
            className="tw-w-full tw-py-3 tw-border-b tw-border-[#66727F]"
          />
          <button
            type="submit"
            className="tw-flex tw-h-[32px] tw-w-[190px] tw-flex-row tw-items-center tw-justify-center tw-rounded-full tw-bg-cyan-800 tw-text-center tw-text-[14px] tw-font-medium tw-font-roboto tw-text-white tw-px-5"
          >
          {t('HomePage.leaveComment')}

          </button>
        </form>
      </div>
    </div>
  );
}
