import { api } from 'store';
import { ApiRoutes } from '../../../shared/constants/ApiRoutes';

export interface IOrgItem {
  id: number;
  name: string;
  image: string;
  count: number;
  services: Array<{
    id: number;
    name: string;
    image: string;
    parrent: number;
    count: number;
  }>;
}

export interface IServiceItem {
  id: number;
  name: string;
  image: string;
  parrent: number;
  count: number;
}

export interface RatingI {
  id: number;
  count: number
}

export interface CommentI  {
  id: number;
  userPicture: string | null;
  userName: string | null;
  userComment: string | null;
  createAt: string | null
}

export interface IServiceDetailFile {
  id: number;
  serviceId: number;
  name: string;
  template: string;
  createAt: string;
  updateAt: string;
  timestamp: string;
  haveTempate: boolean;
  haveFile?: boolean;
  filePath?: string;
  fileName?: string;
  loading?: boolean;
}

export interface IServiceDetail {
  id: number;
  name: string;
  description: string;
  price: number;
  treatmentPrice: number;
  organisation: ValueId;
  files: Array<IServiceDetailFile>;
  createAt: string;
  docType: string;
  updateAt: string;
  timestamp: string;
  averageRating: number;
  appreciated: number;
  ratings: RatingI[];
  comments: CommentI[]
  term: number
}

export interface IServiceCreateRequest {
  serviceId: number;
  files: Array<{
    fileId: number;
    filePath: string;
  }>;
}

export interface IServiceCreateResponse {
  id: number;
  service: ValueId;
  regUser: {
    id: string;
    value: string;
    avatar: string;
  };
  state: number;
  executor: ValueId;
  mainDiscutionId: number;
  userDiscutionId: number;
  files: Array<{
    id?: number;
    fileId: number;
    filePath: string;
    haveFile: true;
  }>;
  documentHistories: Array<{
    state: string;
    startDate: string | Date;
    endDate: string | Date;
    comment: string;
  }>;
  createAt?: string | Date;
  updateAt?: string | Date;
  regUserType: number;
  regUserName: string;
  passportNumber: string;
  phoneNumber: string;
  email: string;
  inn: string;
  orgName: string;
  orgINN: string;
  orgAdress: string;
  readyFiles: string[];
  timestamp?: string;
  transitions?: {
    buttonSettings: {
      btn_openChatWithClient?: IButtonSetting;
      btn_save?: IButtonSetting;
      btn_sign?: IButtonSetting;
      btn_getInvoiceAgain?: IButtonSetting;
    };
  };
}

export interface IServiceUpdateRequest
  extends Omit<IServiceCreateRequest, 'serviceId'> {
  id: number;
}

export interface IServiceSignRequest {
  id: number;
  timestamp?: string;
}

export interface IServiceSearchRequest extends IBaseFilter {
  filters: {
    organisationId: number | null;
    serviceId: number | null;
    state: number | null;
  };
}

export interface IAdminSearch extends IBaseFilter {
  filters: {
    login: string | null;
    inn: string | null;
    name: string | null;
    surName: string | null;
    phone: string | null;
    userType: {
      id: string;
      value: string;
    } | null;
    state: number | null;
  };
}

export interface IServiceSearchItem {
  id: number;
  user: {
    id: string;
    value: string;
    avatar: string;
  };
  service: ValueId;
  state: number;
}

export interface CategoryI {
  id: string,
  value: string
}

const servicesApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Finance reports create
    fetchOrganisationList: builder.query<IListResponse<IOrgItem>, string | null>({
      query: (id) => ({
        url: ApiRoutes.getOrganisationsList + (id ? `?categoryId=${id}` : "") ,
        data: {},
        method: 'POST',
      }),
    }),

    fetchServicesList: builder.query<IServiceItem[], string | number>({
      query: (id) => ({
        url: ApiRoutes.getServiceListByOrgId + id,
        method: 'GET',
      }),
    }),

    getServiceDetail: builder.query<IServiceDetail, string | number>({
      query: (id) => ({
        url: ApiRoutes.getServiceDetail + id,
        method: 'GET',
      }),
    }),

    createService: builder.mutation<
      IServiceCreateResponse,
      IServiceCreateRequest
    >({
      query: (data) => ({
        url: ApiRoutes.createService,
        method: 'POST',
        data,
      }),
    }),

    updateService: builder.mutation<
      IServiceCreateResponse,
      IServiceUpdateRequest
    >({
      query: (data) => ({
        url: ApiRoutes.updateService,
        method: 'POST',
        data,
      }),
    }),

    signService: builder.mutation<IServiceCreateResponse, IServiceSignRequest>({
      query: (data) => ({
        url: ApiRoutes.signService,
        method: 'POST',
        data,
      }),
    }),

    getInvoiceNumberAgain: builder.mutation<boolean, IServiceSignRequest>({
      query: (data) => ({
        url: ApiRoutes.getInvoiceNumberAgain,
        method: 'POST',
        data,
      }),
    }),

    searchService: builder.query<
      IListResponse<IServiceSearchItem>,
      IServiceSearchRequest
    >({
      query: (data) => ({
        url: ApiRoutes.searchService,
        method: 'POST',
        data,
      }),
    }),
    myService: builder.query({
      query: (data) => ({
        url: ApiRoutes.searchService,
        method: 'POST',
        data,
      }),
    }),
    getServiceById: builder.query<IServiceCreateResponse, string>({
      query: (id) => ({
        url: ApiRoutes.getServiceById + id,
        method: 'GET',
        data: {},
      }),
    }),
    getReceiptWithInvoiceNumber: builder.query({
      query: (id) => ({
        url: ApiRoutes.getReceiptById + id,
        method: 'GET',
        data: {},
      }),
    }),

    getSignedText: builder.mutation({
      query: (id: number) => ({
        url: 'api/Egov_Service_Requests/getSignedText/' + id,
        method: 'GET'
      })
    }),

    getCategoryList: builder.query<CategoryI[], void>({
      query: (id) => ({
        url: ApiRoutes.getCategoryByOrgId,
        method: 'GET',
      })
    }),
    getPopularServices: builder.query<any, void>(
      {
        query: (id) => ({
          url: ApiRoutes.getPopularServices,
          method: 'GET',
        })
      }
    )
  }),
});

export const {
  useFetchOrganisationListQuery,
  useFetchServicesListQuery,
  useLazyFetchServicesListQuery,
  useGetServiceDetailQuery,
  useLazyGetServiceDetailQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useSignServiceMutation,
  useSearchServiceQuery,
  useMyServiceQuery,
  useGetServiceByIdQuery,
  useLazyGetServiceByIdQuery,
  useLazyGetReceiptWithInvoiceNumberQuery,
  useGetSignedTextMutation,
  useGetCategoryListQuery, useGetPopularServicesQuery, useGetInvoiceNumberAgainMutation
} = servicesApi;
