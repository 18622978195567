import React from 'react';

interface Props {
  className?: string;
  userImage: string | null;
  userName: string | null;
  userDescription: string | null;
  date : string | null
}

export default function UserProfile({
  userImage,
  userName,
  date,
  userDescription = 'Какая прекрасная песня. Мне 54 года, вроде всякое видел, но иногда невыносимо становится от захлестнувшей мир ненависти. Хочется обязательно пережить:) Дожить до любви и добра.',
  ...props
}: Props) {
  return (
    <div className='tw-flex tw-justify-between tw-items-center tw-gap-4'>
      <div
        {...props}
        className={`${props.className} tw-flex flex-col tw-flex-row tw-justify-center tw-items-start tw-self-stretch tw-gap-6 tw-flex-1`}
      >
        <img
          src={userImage || '/images/img_empty_user.svg'}
          // @ts-ignore
          alt={userName || 'user'}
          className="tw-h-[48px] tw-rounded-[24px] tw-object-cover"
        />
        <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start tw-gap-2.5 md:tw-self-center tw-self-stretch">
          <p className="tw-text-[16px] tw-font-roboto tw-font-medium tw-text-[#66727F]">
            {userName}
          </p>
          <p className="tw-w-full tw-overflow-hidden tw-overflow-ellipsis tw-text-[14px] tw-font-roboto tw-font-normal tw-leading-tight tw-text-[#66727F]">
            {userDescription}
          </p>
        </div>
      </div>
      <div className='tw-pr-4'>
        {date}
      </div>
    </div>
  );
}
