import { Box, IconButton, Paper, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import CustomField from '../../../../components/forms/CustomField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import SourceIcon from '@mui/icons-material/Source';
import { FormikProps } from 'formik';
import { phoneRegexp } from '../helpers/constants';
import {
  ICheckCodeReq,
  IGetCodeReq,
} from '../../../../store/slices/credentials/models';
import { AuthType, UserType } from '..';
import authApi from '../../../../store/slices/credentials/service';
import { toast } from 'react-toastify';
import { IInitialValues, KeyOfInitials } from '../schema';
import { getFieldErrors } from '../../../../shared/utils/yup';
import CustomButton from 'components/ui/CustomButton';

interface IUserForm {
  formik: FormikProps<IInitialValues>;
  handleGetCode?: () => void;
}

const UserForm: FC<IUserForm> = ({ formik, handleGetCode }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const { values, setFieldValue, setFieldTouched } = formik;

  const handleCheckCode = (code: string) => {
    const data: ICheckCodeReq = {
      login: values.userLogin,
      code: Number(code),
      authType: AuthType.auth,
    };
    const toastId = toast.loading('Проверяется введенный вами код');
    authApi.checkCode(data).then((res) => {
      toast.dismiss(toastId);
      if (res?.status === 200) {
        toast.success('Проверка кода успешно');
        setFieldValue('codeChecked', true);
        return;
      }
    });
  };

  const handlers = {
    phone(key = 'phone') {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (value.length === 3) {
          setFieldValue(key, '');
          return;
        }

        if (!value.startsWith('992')) {
          value = '992' + value;
        }

        if (value.length > 12 || !phoneRegexp.test(value)) return;

        setFieldValue(key, value);
        if (key !== 'phone') {
          setFieldValue('phone', value);
        }
      };
    },

    getForKey(key: KeyOfInitials) {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const digits = /^[0-9]{0,}$/;

        if (key === 'code') {
          if (!digits.test(value) || value.length > 4) {
            return;
          }

          if (value.length === 4) {
            handleCheckCode(value);
          }
        }

        setFieldValue(key, value);
        setFieldTouched(key, true);
      };
    },

    getHandleBlur(key: string) {
      return () => {
        setFieldTouched(key, true);
      };
    },
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
      <CustomField
        id="login"
        label="Логин"
        type="text"
        autoComplete="new-password"
        StartIcon={PersonIcon}
        isSmallScreen={isSmallDevice}
        value={values.userLogin || ''}
        onChange={handlers.phone('userLogin')}
        onBlur={handlers.getHandleBlur('userLogin')}
        {...getFieldErrors(formik, 'userLogin')}
      />
      <CustomField
        id="password"
        label="Password"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="new-password"
        StartIcon={LockIcon}
        isSmallScreen={isSmallDevice}
        value={values.password || ''}
        onChange={handlers.getForKey('password')}
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
        onBlur={handlers.getHandleBlur('password')}
        {...getFieldErrors(formik, 'password')}
      />

      <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-space-x-4">
        <CustomField
          fullWidth
          disabled={!values.codeSended}
          id="code"
          label="Код"
          type="text"
          autoComplete="current-password"
          isSmallScreen={isSmallDevice}
          StartIcon={SourceIcon}
          value={values.code}
          onChange={handlers.getForKey('code')}
        />

        {values.codeSended && (
          <CustomButton
            variant="contained"
            onClick={handleGetCode}
            sx={{
              boxShadow: 'none',
              textTransform: 'unset',
              fontSize: '14px',
              borderRadius: '24px',
              width: '90%',
            }}
          >
            Получить код ещё раз
          </CustomButton>
        )}
      </div>
    </Box>
  );
};

export default UserForm;
