import SocialProtectionProfile from './SocialProtectionProfile';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function MainSpheres({
  categories,
  path,
}: {
  categories: any;
  path: string;
}) {
  const navigate = useNavigate();
     const { t } = useTranslation();

  return (
    <>
      {/* services overview section */}
      <div className="tw-flex tw-flex-col tw-items-center tw-mb-9 md:mb-0">
        <div className="tw-mx-auto tw-max-w-[1282px] tw-w-[95%] md:tw-w-full tw-flex tw-flex-col tw-gap-12 tw-px-5 md-tw-px-0">
          <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-gap-5">
            <p className="tw-text-[24px] tw-font-roboto tw-font-semibold tw-text-gray-900 md:tw-text-[26px] lg:tw-text-[28px]">
            {t('HomePage.areas')}

            </p>
          </div>
          <div className="tw-grid lg:tw-grid-cols-6 tw-justify-center tw-gap-[22px] md:tw-grid-cols-3 tw-grid-cols-1 tw-py-7 md:tw-py-0">
            <Suspense fallback={<div>Loading feed...</div>}>
              {categories?.map((d: any, index: number) => (
                <SocialProtectionProfile
                  socialTitle={d.value}
                  key={'cardbox' + index}
                  handleClick={() => {
                    navigate(`${path}/all?category=${d.id}`);
                  }}
                  socialImage={
                    (index + 1) % 2 === 0
                      ? 'images/img_simplification_green_600.svg'
                      : (index + 1) % 3 === 0
                      ? 'images/img_simplification_blue_800.svg'
                      : undefined
                  }
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
