import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Logo from '../../components/ui/Logo';
import MainMenu from '../../components/ui/MainMenu';
import SnowFlake from '../../components/ui/SnowFlake';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import { useDispatch, useSelector } from '../../store';
import { getUserProfile, logout } from '../../store/slices/credentials/actions';
import './index.scss';
import { contentSx, headerStyles, mainStyles, supportStyles } from './styles';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { IUserprofileDetails } from 'store/hooks/userprofile';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';
import LangBtn from 'components/newDesign/langBtn/LangBtn';
import { useTranslation } from 'react-i18next';

interface IPrivateLayout {
  children?: ReactNode;
}
const PrivateLayout: FC<IPrivateLayout> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const ref = useRef<HTMLDivElement>(null);
  const [userDetails] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const isMultilang = process.env.REACT_APP_IS_MULTILANG === 'true';
  const data = useSelector((state) => state.credentials);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handlers = {
    logout() {
      dispatch(
        logout(() => {
          navigate('auth/login');
        })
      );
    },
  };

  useEffect(() => {
    if (data.isAuthorized) {
      dispatch(getUserProfile(0));
    }
  }, [data.isAuthorized]);

  if (!getTokenFromStorage()) {
    return <Navigate to="/auth/login" />;
  }
  const { t } = useTranslation();
  return (
    <Box sx={contentSx}>
      {isSmallDevice && (
        <div style={supportStyles}>
          <div className="tw-flex tw-gap-12 tw-py-2 tw-w-[85%] tw-mx-auto">
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Телефон центра поддержки:</Typography>
              {phone?.length > 0 &&
                phone.map((item: string) => (
                  <a href={`tel:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Электроный адрес:</Typography>
              {email?.length > 0 &&
                email.map((item: string) => (
                  <a href={`mailto:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
      <header
        style={{
          ...headerStyles,
          height: isSmallDevice ? '7rem' : '8rem',
          position: isSmallDevice ? 'sticky' : 'static',
          zIndex: isSmallDevice ? 5 : 0,
        }}
      >
        <Container style={{ maxWidth: '1380px' }}>
          <Box
            display="flex"
            // justifyContent="center"
            alignItems="center"
            position={'relative'}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0px auto',
              }}
            >
              <div
                className="tw-hidden md:tw-flex tw-items-center tw-gap-4 tw-flex-col md:tw-flex-row hover:tw-cursor-pointer tw-w-[500px]"
                onClick={() => navigate('/')}
              >
                <img
                  src="/images/img_logo.svg"
                  alt="Logo"
                  className="tw-h-[60px] tw-object-contain"
                />
                <div className="tw-flex tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch">
                  <p className="tw-font-opensans tw-text-[12px] tw-font-semibold tw-uppercase tw-text-gray-900">
                    {t('HomePage.publicServicesPortal')}
                  </p>
                  <p className="tw-font-opensans tw-text-[12px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                    <>
                      {t('HomePage.ministryOfFinance')}
                      <br />
                      {t('HomePage.republicOfTajikistan')}
                    </>
                  </p>
                </div>
              </div>
              <div
                onClick={() => navigate('/')}
                className="tw-flex md:tw-hidden tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch"
              >
                <p className="tw-font-opensans tw-text-[10px] tw-font-semibold tw-uppercase tw-text-gray-900 tw-tracking-[1.50px]">
                  {t('HomePage.publicServicesPortal')}
                </p>
                <p className="tw-font-opensans tw-text-[8px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                  <>
                    {t('HomePage.ministryOfFinance')}
                    <br />
                    {t('HomePage.republicOfTajikistan')}
                  </>
                </p>
              </div>
              {!isSmallDevice && (
                <Box sx={{ width: '100%' }}>
                  <MainMenu onLogout={handlers.logout} />{' '}
                </Box>
              )}

              {isSmallDevice && (
                <img
                  src="/assets/images/Burger.svg"
                  alt="burgerMenu"
                  width={32}
                  height={32}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              )}
            </Box>
          </Box>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {isSmallDevice && (
              <img
                src="/assets/images/Burger.svg"
                alt="burgerMenu"
                width={32}
                height={32}
                onClick={() => {
                  setOpen(true);
                }}
              />
            )}
            <Logo />
            <Box>
              <MainMenu onLogout={handlers.logout} />
            </Box>
          </Box> */}
        </Container>
      </header>
      <main style={mainStyles}>
        <SnowFlake />
        <Container maxWidth="xl">{children}</Container>
      </main>

      <div
        className="sidenav tw-bg-gradient-to-b tw-from-[#7DE2FC] tw-to-[#B9B6E5]"
        style={{ width: open ? '100%' : '0px' }}
      >
        <div className="tw-py-4 tw-flex tw-justify-end tw-w-[87%] tw-mx-auto tw-mt-12">
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{ color: '#fff' }}
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
        <div className="tw-grid tw-grid-cols-1 tw-divide-y tw-w-[90%] tw-mx-auto tw-p-5 tw-bg-white tw-rounded-xl tw-my-12">
          <p
            onClick={() => {
              navigate('/');
              setOpen(false);
            }}
            className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-2 tw-mb-3"
          >
            {t('HomePage.allServices')}
          </p>

          <p
            onClick={() => {
              navigate('/my-services');
              setOpen(false);
            }}
            className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-4 tw-mb-3"
          >
            {t('HomePage.myServices')}
          </p>

          <p
            onClick={() => {
              navigate('/services/instruction');
              setOpen(false);
            }}
            className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-3"
          >
            {t('HomePage.videoInstructions')}
          </p>
        </div>

        <div className="tw-w-[90%] tw-mx-auto">
          <MainMenu
            isDrawer
            closeDrawer={() => {
              setOpen(false);
            }}
            onLogout={handlers.logout}
          />
        </div>
        {isMultilang && <LangBtn />}
      </div>
    </Box>
  );
};

export default PrivateLayout;
