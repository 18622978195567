import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Paper, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { FC, createContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomButton from '../../../components/ui/CustomButton';
import TabsSegment, {
  ITabsSegmentItem,
} from '../../../components/ui/CustomSegment';
import { TokenAuth } from '../../../components/verifications-old/TokenAuth';
import { useDispatch, useSelector } from '../../../store';
import { getCode, login } from '../../../store/slices/credentials/actions';
import { IGetCodeReq } from '../../../store/slices/credentials/models';
import UserForm from './forms/UserForm';
import { tabsOptions } from './helpers/constants';
import { KeyOfInitials, initialUserValues, validationSchema } from './schema';
import {
  actionContainerSx,
  boxWithMainBg,
  cancelBtnSx,
  closeIconSx,
  containerSx,
  getCertificateFormSx,
  getContainerSx,
  getUserFormSx,
  nextBtnSx,
  paperSx,
  sendBtnSx,
} from './style';
import { useTranslation } from 'react-i18next';

interface ISubmitController {
  submitSignature: number;
  submitToken: () => void;
  resetSubmitSignature: () => void;
}

export enum UserType {
  individual = 1,
  legalEntity = 2,
}

export enum AuthType {
  auth = 1,
  registration = 2,
}

export const TokenSubmitContext = createContext<ISubmitController | null>(null);

const LoginPage: FC = () => {
  const [formType, setFormType] = useState<ITabsSegmentItem | null>(
    tabsOptions[0]
  );
  const [certificateRequired, setCertificateRequired] = useState(false);
  const [tokenVerfified, setTokenVerified] = useState(false);
  const { loading, redirectedUser } = useSelector((state) => state.credentials);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const [submitSignature, setSubmitSignature] = useState(-1);

  const submitToken = () => setSubmitSignature(Math.random());

  const submitController: ISubmitController = useMemo(
    () => ({
      submitSignature,
      submitToken,
      resetSubmitSignature() {
        setSubmitSignature(-1);
      },
    }),
    [submitSignature]
  );

  const initialValues = useMemo(() => {
    if (redirectedUser) {
      return {
        userLogin: redirectedUser.login,
        password: redirectedUser.passWord,
        code: '',
        type: '',
        codeSended: false,
        codeChecked: false,
      };
    }

    return initialUserValues;
  }, [initialUserValues, redirectedUser]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit() {},
  });

  const { values, errors, touched, setFieldValue } = formik;

  const handlers = {
    tabsChange(value: ITabsSegmentItem) {
      setFormType(value);
      // setCertificateRequired(value.value === 'entity');
    },

    onSubmitToken() {
      submitController.submitToken();
    },

    onSendCode() {
      if (loading) return;
      if (
        values.codeSended &&
        values.code &&
        (!certificateRequired || tokenVerfified)
      ) {
        // login
        const data: any = {
          userLogin: values.userLogin,
          password: values.password,
          grantType: 'password',
          code: String(values.code),
          userType: formType?.code,
          // type: 2,
        };

        dispatch(
          login({
            data,
            onSuccess: () => {
              navigate('/profile/account');
            },
          })
        );
      } else if (!values.codeSended) {
        const data: IGetCodeReq = {
          login: values.userLogin,
          userType:
            formType?.value === 'individual'
              ? UserType.individual
              : UserType.legalEntity,
          // phoneOrMail: values.phone,
          authType: AuthType.auth,
          password: values.password,
        };
        dispatch(
          getCode({
            data,
            onSuccess: () => {
              setFieldValue('codeSended', true);
              toast.success(
                `Код успешно отправлен на номер ${values.userLogin}`
              );
            },
          })
        );
      }
    },

    onCancel() {
      setTokenVerified(false);
      setCertificateRequired(true);
      formik.resetForm();
    },
  };

  const isSubmitDisabled = () => {
    const isAllFieldsTouched = ['userLogin', 'password'].every((key) =>
      Boolean(touched[key as KeyOfInitials])
    );

    return (
      Boolean(values.codeSended && !values.codeChecked) ||
      Boolean(!formik.isValid || !isAllFieldsTouched)
    );
  };

  const certificateFormVisible = certificateRequired && !tokenVerfified;
  const { t } = useTranslation();

  return (
    <TokenSubmitContext.Provider value={submitController}>
      <Box className="sdflk" sx={getContainerSx(focused)}>
        <div className="tw-bg-main-bg" style={boxWithMainBg} />
        {focused && (
          <IconButton
            onClick={() => setFocused(false)}
            size="large"
            className="popup-btn"
            sx={closeIconSx}
          >
            <CloseIcon sx={{ fontSize: '40px' }} />
          </IconButton>
        )}
        <Box sx={containerSx}>
          <Box
            sx={{
              width: isSmallDevice ? '320px' : '48rem',
            }}
            onClick={() => {
              if (!isSmallDevice) {
                setFocused(true);
              }
            }}
          >
            <Box mb={5}>
              <TabsSegment
                items={tabsOptions}
                value={formType}
                onChange={handlers.tabsChange}
              />
            </Box>
            <Paper sx={paperSx(isSmallDevice)}>
              {!certificateFormVisible && (
                <CustomButton
                  size="small"
                  onClick={() => navigate('/auth/registration')}
                  variant="contained"
                  sx={{
                    width: isSmallDevice ? '230px' : '280px',
                    height: isSmallDevice ? '48px' : '60px',
                    fontSize: isSmallDevice ? '14px' : '18px',
                    position: 'absolute',
                    top: '0',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
              {t('HomePage.registration')}

                </CustomButton>
              )}

              <Box height={'320px'} overflow={'hidden'} position={'relative'}>
                <Box sx={getUserFormSx(certificateFormVisible)}>
                  <UserForm
                    formik={formik}
                    handleGetCode={() => {
                      const data: IGetCodeReq = {
                        login: values.userLogin,
                        userType:
                          formType?.value === 'individual'
                            ? UserType.individual
                            : UserType.legalEntity,
                        // phoneOrMail: values.phone,
                        authType: AuthType.auth,
                        password: values.password,
                      };
                      dispatch(
                        getCode({
                          data,
                          onSuccess: () => {
                            setFieldValue('codeSended', true);
                            toast.success(
                              `Код успешно отправлен на номер ${values.userLogin}`
                            );
                          },
                        })
                      );
                    }}
                  />
                </Box>
                <Box sx={getCertificateFormSx(certificateFormVisible)}>
                  {certificateFormVisible && (
                    <TokenAuth
                      onTokenVerified={() => {
                        setTokenVerified(true);
                        submitController.resetSubmitSignature();
                      }}
                    />
                  )}
                </Box>
              </Box>
              <div className="tw-text-center">
                <Link
                  to="/auth/forget-password"
                  className="tw-font-bold tw-text-primary"
                  style={{ fontSize: 16 }}
                >
                  {t('HomePage.forgotPassword')}

                </Link>
              </div>
              <Box sx={actionContainerSx}>
                {/* {formType?.value === 'entity' ? (
                  <>
                    <CustomButton
                      sx={cancelBtnSx}
                      variant="outlined"
                      color="primary"
                      onClick={handlers.onCancel}
                    >
                      Отмена
                    </CustomButton>
                    <CustomButton
                      sx={nextBtnSx}
                      variant="contained"
                      color="primary"
                      loading={loading}
                      onClick={
                        certificateFormVisible
                          ? handlers.onSubmitToken
                          : handlers.onSendCode
                      }
                    >
                      {certificateFormVisible || !values.codeSended
                        ? 'Дальше'
                        : 'Готово'}
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton
                    disabled={isSubmitDisabled()}
                    onClick={handlers.onSendCode}
                    sx={sendBtnSx(isSmallDevice)}
                    variant="contained"
                    color="primary"
                    loading={loading}
                  >
                    {values.codeSended ? 'Войти' : 'Отправить код'}
                  </CustomButton>
                )} */}

                <CustomButton
                  disabled={isSubmitDisabled()}
                  onClick={handlers.onSendCode}
                  sx={sendBtnSx(isSmallDevice)}
                  variant="contained"
                  color="primary"
                  loading={loading}
                >
                  {values.codeSended ? `${t('HomePage.login')}
` : `${t('HomePage.sendCode')}
`}
                </CustomButton>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </TokenSubmitContext.Provider>
  );
};

export default LoginPage;
