import { Box, Button, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';

interface IActionsGroup {
  onBack: () => void;
  children?: ReactNode;
}

export const ActionsGroup: FC<IActionsGroup> = ({ onBack, children }) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const {t} = useTranslation()
  return (
    <Box display="flex" alignItems={isSmallDevice ? 'flex-start' : 'center'} justifyContent="space-between" flexDirection={isSmallDevice ? 'column' : 'row'}>
      <Box>
        <Button
          onClick={onBack}
          startIcon={<ArrowBackIosNewIcon color="primary" />}
        >
          {t('HomePage.back')}

        </Button>
      </Box>
      <Box display="flex" gap="24px">
        {children}
      </Box>
    </Box>
  );
};
