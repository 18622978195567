import { generateOptions, generateStates } from 'shared/utils/generateOptions';
import {t} from 'i18next';
export enum ServiceStatus {
  New = 1,
  Concelyar1 = 2,
  Resolution = 3,
  Execution = 4,
  Collect = 5,
  Concelyar2 = 6,
  Done = 7,
}

export const ServiceStatusName = {
  [ServiceStatus.New]: t("HomePage.new"),
  [ServiceStatus.Concelyar1]: t('HomePage.office')  ,
  [ServiceStatus.Resolution]: t('HomePage.resolution')  ,
  [ServiceStatus.Execution]: t('HomePage.execution') ,
  [ServiceStatus.Collect]: t('HomePage.documentPreparation') ,
  [ServiceStatus.Concelyar2]: t('HomePage.office') ,
  [ServiceStatus.Done]: t('HomePage.completed') ,
}

export const serviceStatusOptions = generateOptions(ServiceStatusName);
export const serviceStates = generateStates(ServiceStatusName);
