import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from './en.json';
import commonRU from './ru.json';
import commonTG from './tg.json';

export enum Language {
    RU = 'ru',
    EN = 'en',
    TJ = 'tj',
}

export const LANGUAGE_KEY = "lang";

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            [Language.TJ]: {
                translation: commonTG
            },
            [Language.RU]: {
                translation: commonRU
            },
            [Language.EN]: {
                translation: commonEN
            },
        },
        lng: localStorage.getItem(LANGUAGE_KEY) || Language.RU,
        fallbackLng: Language.RU,
        interpolation: {
            escapeValue: false,
        },
        saveMissing: true,
    });

export { i18next }