import React from 'react';
import {
  BreadcrumbLink,
  Breadcrumb,
  BreadcrumbItem,
} from 'components/newDesign/Breadcrumb';

import VideoPlayer from 'components/newDesign/VideoPlayer';
import { useTranslation } from 'react-i18next';
 
function index() {
  const { t } = useTranslation();
  return (
    <div className="tw-w-[90%] md:tw-w-[85%] tw-mx-auto tw-max-w-[1282px] tw-my-8">
      <Breadcrumb
        separator={
          <img
            src="/images/img_breadcrumb_vector.svg"
            alt="Arrow Right"
            className="tw-h-[10px]"
          />
        }
        className="tw-flex tw-flex-wrap tw-items-center tw-gap-6 tw-self-stretch"
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#66727F]">
            {t('HomePage.home')}

            </p>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#66727F]">
          {t('HomePage.videoInstructions')}
          </p>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="tw-mt-8 tw-w-full tw-min-h-[450px] tw-bg-[#F7F8F9] tw-rounded-2xl tw-p-9 tw-grid md:tw-grid-cols-2 tw-gap-14 tw-grid-rows-1">
        <VideoPlayer
          cover="/images/vazorat.png"
          desc={t('HomePage.howToPayForService')}

          videoPath="assets/videos/Тарзи_пардохт_намудани_хизматрасонӣ.mp4"
        />
        <VideoPlayer
          cover="/images/vazorat.png"
          desc={t('HomePage.howToGetService')}

          videoPath="assets/videos/Тарзи_тартиб_додани_хизматрасонӣ.mp4"
        />
        <VideoPlayer
          cover="/images/vazorat.png"
          desc={t('HomePage.howToGetFeedback')}

          videoPath="assets/videos/Как получить обратную связь_.mp4"
        />
        
        <VideoPlayer
          cover="/images/vazorat.png"
          desc={t('HomePage.howToGetCompletedService')}

          videoPath="assets/videos/Чӣ_тавр_хизматрасонии_омодашударо_дастрас_намоем.mp4"
        />
      </div>
    </div>
  );
}

export default index;
