import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Carousel } from '../../../components/ui/Carousel';
import { moduleRoutes } from './helpers/constants';
import { ServiceCategory } from '../../../components/ui/ServiceCategory';
import { ServiceItem } from '../../../components/ui/ServiceItem';
import { useDispatch } from '../../../store';
import {
  useFetchOrganisationListQuery,
  useLazyFetchServicesListQuery,
  useGetCategoryListQuery,
  useGetPopularServicesQuery,
} from '../../../store/hooks/services';
import useUrlParams from '../../../shared/utils/useUrlParams';
import Segmented from 'components/ui/Segmented/Segmented';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';
import HeroSection from 'components/newDesign/HeroSection';
import GovernmentServicesSection from 'components/newDesign/GovernmentServicesSection';
import MainSpheres from 'components/newDesign/MainSpheres';
import MainPopularServices from 'components/newDesign/MainPopularServices';
import MainStatistic from 'components/newDesign/MainStatistic';

const EgovServices: FC = () => {
  const [params, setUrlParams] = useUrlParams();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { data: orgs } = useFetchOrganisationListQuery(selectedValue);
  const { data: popularServices } = useGetPopularServicesQuery();
  const { data: categories } = useGetCategoryListQuery();
  const [sectionsTab, setSectionsTab] = useState(1);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 560px)');

  const organisatios = useMemo(() => {
    return (
      orgs?.items?.map((item) => ({
        iconUrl: item.image,
        name: item.name,
        id: item.id,
        services: item.services,
      })) || []
    );
  }, [orgs]);

  return (
    // <Box sx={{ py: 4 }}>
    //   <Box pt={10} pb={5}>
    //     <Carousel
    //       width={192}
    //       items={organisatios}
    //       renderView={(data) => (
    //         <ServiceCategory
    //           onClick={() => {
    //             setUrlParams('orgId', data.id);
    //           }}
    //           active={Number(params.orgId) === data.id}
    //           {...data}
    //         />
    //       )}
    //     />
    //     <Segmented
    //       options={
    //         categories?.map((el: { id: string; value: string }) => ({
    //           label: el.value,
    //           value: el.id,
    //         })) || []
    //       }
    //       value={selectedValue}
    //       onChange={setSelectedValue}
    //     />
    //   </Box>
    //   {serviceList.length > 0 && (
    //     <Box
    //       sx={{
    //         display: 'grid',
    //         gridTemplateColumns: isSmallDevice
    //           ? 'repeat(1, 1fr)'
    //           : 'repeat(3, 1fr)',
    //         gap: 2,
    //         px: 2,
    //         py: 5,
    //         borderRadius: '20px',
    //         boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
    //         backgroundColor: '#fff',
    //       }}
    //     >
    //       {serviceList.map((item) => {
    //         return (
    //           <ServiceItem
    //             id={String(item.id)}
    //             key={item.id}
    //             route={moduleRoutes[item.id] || ''}
    //             name={item.name}
    //           />
    //         );
    //       })}
    //     </Box>
    //   )}
    // </Box>

    <div className="tw-w-full tw-flex tw-flex-col lg:tw-gap-[72px] md:tw-gap-[54px] sm:tw-gap-9 tw-mt-20">
      <GovernmentServicesSection organs={organisatios} path="profile" />

      <div className="tw-mx-auto tw-flex md:tw-hidden tw-flex-row tw-items-start tw-gap-7">
        <button
          onClick={() => setSectionsTab(1)}
          className={`tw-w-[99px] tw-h-[32px] tw-rounded-lg ${
            sectionsTab === 1
              ? 'tw-bg-[#007B9E] tw-text-white'
              : 'tw-bg-[#007B9E33] tw-text-[#007B9E]'
          } tw-twxt-center tw-text-[14px] tw-font-bold tw-font-roboto`}
        >
          Сферы
        </button>
        <button
          onClick={() => setSectionsTab(2)}
          className={`tw-w-[185px] tw-h-[32px] tw-rounded-lg ${
            sectionsTab === 2
              ? 'tw-bg-[#007B9E] tw-text-white'
              : 'tw-bg-[#007B9E33] tw-text-[#007B9E]'
          } tw-twxt-center tw-text-[14px] tw-font-medium tw-font-roboto`}
        >
          Популярные услуги
        </button>
      </div>

      {(!isSmallDevice || sectionsTab === 1) && (
        <MainSpheres categories={categories} path="profile" />
      )}
      {(!isSmallDevice || sectionsTab === 2) && (
        <MainPopularServices items={popularServices} />
      )}
      <MainStatistic />
    </div>
  );
};

export default EgovServices;
