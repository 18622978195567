import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';
import {
  AvatarGroup,
  Badge,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { Link, NavLink } from 'react-router-dom';
import { FC, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  IUserprofileDetails,
  useGetUserprofileDetailsQuery,
} from 'store/hooks/userprofile';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';
import { useTranslation } from 'react-i18next';

interface IMainMenu {
  onLogout: () => void;
  isDrawer?: boolean;
  closeDrawer?: () => void;
}

const MainMenu: FC<IMainMenu> = ({
  onLogout,
  isDrawer = false,
  closeDrawer,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userDetails] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );
  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: profileData, refetch } = useGetUserprofileDetailsQuery();

  const [searchbarExpanded, setSearchbarExpanded] = useState(false);
  const navTypographyParams: any = {
    variant: 'h6',
    color: 'primary',
    fontWeight: 700,
    sx: { fontSize: '1.6rem' },
  };

  if (isDrawer) {
    return (
      <>
        <Tooltip title="Account settings" disableHoverListener>
          <div className="tw-relative">
            <div
              className={`tw-absolute tw-z-20 ${
                profileData?.authorized && profileData?.displayName
                  ? 'tw-top-[-7px] tw-left-12'
                  : profileData?.authorized && !profileData?.displayName
                  ? 'tw-top-[-11.5px] tw-left-[60px]'
                  : !profileData?.authorized && profileData?.displayName
                  ? 'tw-top-[-11px] tw-left-12'
                  : 'tw-top-[-10px] tw-left-[60px]'
              }`}
            >
              {profileData?.authorized ? (
                <GridCheckCircleIcon fontSize="medium" color="success" />
              ) : (
                <ErrorIcon fontSize="medium" color="error" />
              )}
            </div>
            <Button
              startIcon={
                <>
                  <Avatar
                    src={profileData?.avatar || '/assets/images/ava.png'}
                    sx={{ width: 30, height: 30 }}
                  />
                </>
              }
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleClick}
              size="small"
              sx={{ minWidth: '150px', textTransform: 'none' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{ mb: 0, color: '#fff' }}
              >
                {profileData?.displayName || ''}
              </Typography>
            </Button>
          </div>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{
            p: 0,
            borderRadius: 1,
          }}
          MenuListProps={{
            sx: {
              p: 0,
            },
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: '10px !important',
              minWidth: '150px',
              maxWidth: '200px',
              overflow: 'hidden',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
              mt: 1.5,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {userDetails?.roles?.includes(1) && (
            <Link
              to="/admin"
              onClick={() => {
                closeDrawer?.();
              }}
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                  <AccountCircleIcon />
                  <ListItemText sx={{ flex: 1 }} primary="Администрирование" />
                </ListItemButton>
              </ListItem>
            </Link>
          )}
          <Link
            to="/profile/account"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              closeDrawer?.();
            }}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                <AccountCircleIcon />
                <ListItemText sx={{ flex: 1 }} primary="Профиль" />
              </ListItemButton>
            </ListItem>
          </Link>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={onLogout}
              sx={{ gap: 1, justifyContent: 'start' }}
            >
              <Logout />
              <ListItemText sx={{ flex: 1 }} primary="Выход" />
            </ListItemButton>
          </ListItem>
        </Menu>
      </>
    );
  }
    const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isSmallDevice ? null : (
          <ul className="tw-flex tw-items-center tw-gap-6 tw-w-[40%]">
            <li>
              <NavLink to="/">
                <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900">
                {t('HomePage.allServices')}
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-services">
                <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900">
                {t('HomePage.myServices')}
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/services/instruction">
                <p className="tw-font-inter tw-text-[16px] tw-font-medium tw-text-gray-900 tw-py-2">
                {t('HomePage.videoInstructions')}
                </p>
              </NavLink>
            </li>
          </ul>
        )}

        {isSmallDevice ? null : (
          <Box sx={{ width: '57%' }}>
            {/* <IconButton sx={{ mx: 2 }}>
              <NotificationsNoneIcon fontSize="large" />
            </IconButton> */}

            <div className="tw-flex tw-gap-7">
              <div className="tw-flex tw-gap-5 tw-items-start">
                <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-2 tw-items-start tw-justify-center">
                  <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  {t('HomePage.hotlinePhone')}
                  </p>
                  {phone?.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-medium tw-tracking-[0.28px] tw-text-gray-900"
                      href={`tel:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2">
                  <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  {t('HomePage.emailAddress')}
                  </p>
                  {email?.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-normal tw-text-gray-900"
                      href={`mailto:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
              <Tooltip title="Account settings" disableHoverListener>
                <div className="tw-relative">
                  <div
                    className={`tw-absolute ${
                      profileData?.authorized && profileData?.displayName
                        ? 'tw-top-[-7px] tw-left-12'
                        : profileData?.authorized && !profileData?.displayName
                        ? 'tw-top-[-11.5px] tw-left-[60px]'
                        : !profileData?.authorized && profileData?.displayName
                        ? 'tw-top-[-11px] tw-left-12'
                        : 'tw-top-[-10px] tw-left-[60px]'
                    }`}
                  >
                    {profileData?.authorized ? (
                      <GridCheckCircleIcon fontSize="medium" color="success" />
                    ) : (
                      <ErrorIcon fontSize="medium" color="error" />
                    )}
                  </div>
                  <Button
                    startIcon={
                      <>
                        <Avatar
                          src={profileData?.avatar || '/assets/images/ava.png'}
                          sx={{ width: 30, height: 30 }}
                        />
                      </>
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    size="small"
                    sx={{ minWidth: '150px', textTransform: 'none' }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Typography variant="h6" color="primary" sx={{ mb: 0 }}>
                      {profileData?.displayName || ''}
                    </Typography>
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Box>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          p: 0,
          borderRadius: 1,
        }}
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '10px !important',
            minWidth: '150px',
            maxWidth: '200px',
            overflow: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {profileData?.roles?.includes(1) && (
          <Link
            to="/admin"
            onClick={() => {
              closeDrawer?.();
            }}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                <AccountCircleIcon />
                <ListItemText sx={{ flex: 1 }} primary="Администрирование" />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
        <Link
          to="/profile/account"
          style={{ textDecoration: 'none' }}
          onClick={() => {
            closeDrawer?.();
          }}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
              <AccountCircleIcon />
              <ListItemText sx={{ flex: 1 }} primary={t('HomePage.profile')}
 />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={onLogout}
            sx={{ gap: 1, justifyContent: 'start' }}
          >
            <Logout />
            <ListItemText sx={{ flex: 1 }} primary={t('HomePage.logout')}
 />
          </ListItemButton>
        </ListItem>
      </Menu>
    </React.Fragment>
  );
};

export default MainMenu;
