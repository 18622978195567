import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { mainStyles } from './style';
import Logo from '../../components/ui/Logo';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import SnowFlake from '../../components/ui/SnowFlake';
import './index.scss';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';
import { supportStyles } from 'layouts/private/styles';
import LangBtn from 'components/newDesign/langBtn/LangBtn';
import { useTranslation } from 'react-i18next';

interface IPublicLayout {
  children?: ReactNode;
}

const PublicLayout: FC<IPublicLayout> = ({ children }) => {
  const isAnimationAllowed = parseInt(
    process.env.REACT_APP_SEASON_ANIMATION || ''
  );
  const isMultilang = process.env.REACT_APP_IS_MULTILANG === 'true';
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const navigate = useNavigate();
  if (getTokenFromStorage()) {
    return <Navigate to={'/services'} />;
  }
  const { t } = useTranslation();
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <header className="tw-w-full tw-shadow-md">
        <div className="tw-w-full tw-mx-auto tw-max-w-[1282px] tw-py-3.5 tw-flex tw-items-center tw-justify-between tw-gap-5 md:tw-px-5">
          <div className="tw-hidden md:tw-flex lg:tw-w-[38%] tw-justify-center tw-w-full">
            <div
              className="tw-flex tw-w-full tw-items-center tw-gap-4 tw-flex-col md:tw-flex-row hover:tw-cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                src="/images/img_logo.svg"
                alt="Logo"
                className="tw-h-[60px] tw-object-contain"
              />
              <div className="tw-flex tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch">
                <p className="tw-font-opensans tw-text-[12px] tw-font-semibold tw-uppercase tw-text-gray-900">
                  {t('HomePage.publicServicesPortal')}
                </p>
                <p className="tw-font-opensans tw-text-[12px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                  <>
                    {t('HomePage.ministryOfFinance')}
                    <br />
                    {t('HomePage.republicOfTajikistan')}
                  </>
                </p>
              </div>
            </div>
          </div>

          <div className="tw-flex lg:tw-w-[36%] tw-items-center tw-justify-between tw-gap-5 tw-w-full tw-shadow-md md:tw-shadow-none tw-py-3 md:tw-py-0">
            <div className="tw-flex lg:tw-w-[64%] tw-justify-between tw-items-start tw-gap-5 tw-w-[80%] tw-mx-auto md:tw-mx-0">
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  {t('HomePage.hotlinePhone')}
                </p>
                {phone?.length > 0 &&
                  phone.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-medium tw-tracking-[0.28px] tw-text-gray-900"
                      href={`tel:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-0.5">
                <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#25252566]">
                  {t('HomePage.emailAddress')}
                </p>
                {email?.length > 0 &&
                  email.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-normal tw-text-gray-900"
                      href={`mailto:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="tw-block md:tw-hidden tw-w-full tw-relative">
          <div className="tw-w-[80%] tw-mx-auto tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-5">
            <div
              onClick={() => navigate('/')}
              className="tw-flex tw-flex-1 tw-flex-col tw-space-y-1.5 sm:tw-self-stretch"
            >
              <p className="tw-font-opensans tw-text-[10px] tw-font-semibold tw-uppercase tw-text-gray-900 tw-tracking-[1.50px]">
                {t('HomePage.ministryOfFinanceRT')}
              </p>
              <p className="tw-font-opensans tw-text-[8px] tw-font-medium tw-leading-[14px] tw-tracking-[1.00px] tw-text-[#252525B2]">
                <>
                  {t('HomePage.managementInformationSystem')}

                  <br />
                  {t('HomePage.publicFinance')}
                </>
              </p>
            </div>
          </div>
        </div>
      </header>

      <main style={mainStyles}>
        {isAnimationAllowed > 0 && <SnowFlake />}
        <Outlet />
        {isMultilang && <LangBtn />}
      </main>
    </Box>
  );
};

export default PublicLayout;
