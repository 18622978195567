import { useMediaQuery } from '@mui/material';
import { Input } from './Input';
import { CloseSVG } from './Input/close';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HeroSection() {
  const [searchBarValue, setSearchBarValue] = React.useState('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 660px)');
  const { t } = useTranslation();
  

  return (
    <div className="md:tw-h-[470px] tw-w-full tw-h-[300px] tw-z-10 tw-relative">
      <video
        preload="none"
        poster={
          isSmallDevice
            ? 'images/mobileBgVideoPoster.webp'
            : 'images/bgVideoPoster.webp'
        }
        className="tw-w-full tw-h-full -tw-z-10 tw-object-cover"
        src={
          isSmallDevice ? '/cover/mobile-bg-cover.mp4' : '/cover/bg-cover.webm'
        }
        loop={true}
        muted={true}
        autoPlay
      ></video>
      <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full">
        <div className="tw-flex tw-items-start tw-h-full tw-mx-auto tw-max-w-[1282px] tw-w-[90%] tw-flex-col tw-justify-center">
          <div className="tw-flex tw-flex-col tw-gap-[30px] tw-w-full md:tw-w-[65%] tw-items-start tw-justify-center">
            <section className="tw-w-full tw-flex tw-flex-col tw-gap-6">
              <h2 className="tw-w-full lg:tw-text-[32px] tw-font-roboto tw-font-semibold tw-leading-tight tw-text-white md:tw-text-[30px] tw-text-[22px]">
              {t('HomePage.financialSystemDescription')}

              </h2>
              <h3 className="tw-text-[16px] tw-font-roboto tw-font-normal tw-text-white">
              {t('HomePage.usePublicServices')}

              </h3>
            </section>
            <div className="tw-flex tw-items-center tw-gap-6 tw-flex-row">
              <Input
                name="search"
                placeholder={`${t("HomePage.enterQuery")}`}
                value={searchBarValue}
                onChange={(e) => setSearchBarValue(e.target.value)}
                suffix={
                  searchBarValue?.length > 0 ? (
                    <CloseSVG
                      onClick={() => setSearchBarValue('')}
                      height={24}
                      width={24}
                      fillColor="#007b9eff"
                    />
                  ) : (
                    <img
                      src="images/img_search_cyan_800.svg"
                      alt="Search"
                      className="tw-h-[24px] tw-w-[24px] tw-object-contain"
                    />
                  )
                }
                className="tw-flex tw-h-[48px] tw-items-center tw-justify-center tw-gap-4 tw-rounded-lg tw-bg-white tw-px-6 tw-text-[14px] tw-text-[#25252599]"
              />
              <img
                src="images/img_close.svg"
                alt="Close"
                className="tw-h-[32px] sm:tw-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
