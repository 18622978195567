import React, { Suspense, useState, useMemo } from 'react';
import { SelectBox } from 'components/newDesign/SelectBox';
import { Input } from 'components/newDesign/Input';
import {
  BreadcrumbLink,
  Breadcrumb,
  BreadcrumbItem,
} from 'components/newDesign/Breadcrumb';
import { useParams } from 'react-router-dom';
import DocumentSummary from 'components/newDesign/DocumentSummary';
import { CloseSVG } from 'components/newDesign/Input/close';
import { useFetchOrganisationListQuery } from 'store/hooks/services';

import InfoModal from '../modals/InfoModal';
import AlertModal from '../modals/AlertModal';
import { useTranslation } from 'react-i18next';




export default function ServicesSection2({
  categoryId,
}: {
  categoryId: string | null;
}) {

  const { t } = useTranslation();
  const [filtres, setFilters] = useState({
    search: '',
    type: null,
    proccess: null,
  });
 
  const typeOptions = [
    { label:  t('HomePage.paid'), value: true },
    { label: t('HomePage.free') , value: false },
  ];
  
  const processOptions = [
    { label: t('HomePage.automatic')  , value: true },
    { label: t('HomePage.semiAutomatic') , value: false },
  ];

  const [isFilter, setIsFilter] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const { data: orgs } = useFetchOrganisationListQuery(categoryId);

  const { orgId } = useParams();

  const [serviceId, setServiceId] = useState<number | null>(null);

  const organisatios = useMemo(() => {
    return (
      orgs?.items?.map((item) => ({
        iconUrl: item.image,
        name: item.name,
        id: item.id,
        services: item.services,
      })) || []
    );
  }, [orgs]);

  const services = useMemo(() => {
    if (orgId) {
      if (orgId === 'all') {
        return organisatios
          ?.flatMap((el: any) => el.services || [])
          ?.filter((el: any) => {
            if (filtres.search.length) {
              return el.name?.includes(filtres.search);
            }
            if (filtres.type !== null) {
              return el.mustPaid === filtres.type;
            }

            if (filtres.proccess !== null) {
              return el.automatic === filtres.proccess;
            }

            return el;
          });
      }

      return (
        organisatios.find((item) => item.id === +orgId)?.services || []
      )?.filter((el: any) => {
        if (filtres.search.length) {
          return el.name === filtres.search;
        }

        return el;
      });
    }
  }, [orgId, organisatios, isFilter]);
 
  return (
    <>
      {/* services ministry section */}
      <div className="tw-flex tw-flex-col tw-items-center">
        <div className="tw-w-full tw-mx-auto tw-max-w-[1282px] tw-flex tw-flex-col tw-items-center tw-px-5 md:tw-px-0 tw-min-h-[400px]">
          <Breadcrumb
            separator={
              <img
                src="/images/img_breadcrumb_vector.svg"
                alt="Arrow Right"
                className="tw-h-[10px]"
              />
            }
            className="tw-flex tw-flex-wrap tw-items-center tw-gap-6 tw-self-stretch"
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">
                <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#66727F]">
                {t('HomePage.home')}

                </p>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <p className="tw-font-inter tw-text-[12px] tw-font-normal tw-text-[#66727F]">
                {orgId === 'all'
                  ? 'Все услуги'
                  : organisatios?.find((el: any) => el.id === +(orgId || ''))
                      ?.name}
              </p>
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="tw-mt-6 tw-flex tw-gap-7 md:tw-gap-2.5 tw-self-stretch tw-flex-col md:tw-flex-row">
            <Input
              name="search"
              placeholder={`${t('HomePage.enterQuery')}`}
              value={filtres.search}
              onChange={(e) =>
                setFilters((prev: any) => ({ ...prev, search: e.target.value }))
              }
              suffix={
                filtres.search?.length > 0 ? (
                  <CloseSVG
                    onClick={() => {
                      setFilters((prev: any) => ({ ...prev, search: '' }));
                      setIsFilter(!isFilter);
                    }}
                    height={24}
                    width={24}
                    fillColor="#007b9eff"
                  />
                ) : (
                  <img
                    src="/images/img_search_cyan_800.svg"
                    alt="Search"
                    className="tw-h-[24px] tw-w-[24px] tw-object-contain"
                  />
                )
              }
              className="tw-flex tw-h-[40px] md:tw-w-[26%] tw-items-center tw-justify-center tw-gap-0.5 tw-rounded-lg tw-border tw-border-solid tw-border-cyan-800 tw-bg-white tw-px-4 tw-text-[14px] tw-text-[#25252599] tw-w-full"
            />
            <SelectBox
              indicator={
                <img
                  src="/images/img_arrowdropdownfilled.svg"
                  alt="Arrowdropdownfilled"
                  className="tw-h-[24px] tw-w-[24px]"
                />
              }
              onChange={(e: any) => {
                if (e === null) {
                  setIsFilter(!isFilter);
                  setFilters((prev: any) => ({ ...prev, type: e }));
                } else {
                  setFilters((prev: any) => ({ ...prev, type: e.value }));
                }
              }}
              name="select_three"
           
              placeholder={`${t('HomePage.serviceType')}`}
              options={typeOptions}
              className="tw-flex md:tw-w-[18%] tw-gap-0.5 tw-rounded-lg tw-border tw-border-solid tw-border-cyan-800 tw-bg-white tw-px-4 tw-py-2 tw-text-[14px] tw-text-[#25252599] tw-w-full"
            />
            <SelectBox
              indicator={
                <img
                  src="/images/img_arrowdropdownfilled.svg"
                  alt="Arrowdropdownfilled"
                  className="tw-h-[24px] tw-w-[24px]"
                />
              }
              name="select_five"
              onChange={(e: any) => {
                if (e === null) {
                  setIsFilter(!isFilter);
                  setFilters((prev: any) => ({ ...prev, proccess: e }));
                } else {
                  setFilters((prev: any) => ({ ...prev, proccess: e.value }));
                }
              }}
              placeholder={`${t('HomePage.processType')}`}
              options={processOptions}
              className="tw-flex md:tw-w-[18%] tw-gap-0.5 tw-rounded-lg tw-border tw-border-solid tw-border-cyan-800 tw-bg-white tw-px-4 tw-py-2 tw-text-[14px] tw-text-[#25252599] tw-w-full"
            />
            <button
              onClick={() => setIsFilter(!isFilter)}
              className="tw-flex tw-h-[40px] tw-min-w-[108px] tw-flex-row tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-cyan-800 tw-bg-cyan-800 md:tw-px-[31px] tw-text-center tw-text-[14px] tw-font-bold tw-text-white tw-px-5"
            >
        {t('HomePage.search')}

            </button>
          </div>
          <div className="tw-mt-12 tw-grid lg:tw-grid-cols-4 tw-justify-center tw-gap-6 tw-self-stretch md:tw-grid-cols-2 tw-grid-cols-1">
            <Suspense fallback={<div>Loading feed...</div>}>
              {services?.map((d: any, index) => (
                <DocumentSummary
                  handleClick={() => {
                    setModalOpen(true);
                    setServiceId(d.id);
                  }}
                  documentTitle={d.name}
                  key={'sectioncard' + index}
                  {...d}
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>

      {serviceId && (
        <>
          <InfoModal
            serviceId={serviceId}
            isOpen={modalOpen}
            handleClose={() => setModalOpen(false)}
            getServiceClick={() => setModalOpen1(true)}
          />

          <AlertModal
            isOpen={modalOpen1}
            handleClose={() => setModalOpen1(false)}
          />
        </>
      )}
    </>
  );
}
