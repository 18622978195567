import React from 'react';
import StatisticCard from './StatisticCard';
import { useGetStatisticDataQuery } from 'store/hooks/general';

function MainStatistic() {
  const { data } = useGetStatisticDataQuery();
  return (
    <div className="tw-mx-auto tw-mb-9 md:mb-0 tw-max-w-[1282px] tw-w-[95%] md:tw-w-full tw-flex tw-flex-col tw-gap-12 tw-px-5 md-tw-px-0">
      <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-gap-5">
        <p className="tw-text-[24px] tw-font-roboto tw-font-semibold tw-text-gray-900 md:tw-text-[26px] lg:tw-text-[28px]">
          Статистика
        </p>
      </div>

      <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-9">
        <StatisticCard
          bgColor="tw-bg-blue-500"
          count={data?.incommingRequests || 0}
          title="Поступившие заявки"
        />

        <StatisticCard
          bgColor="tw-bg-amber-400"
          count={data?.executingRequests || 0}
          title="В процессе рассмотрения"
        />

        <StatisticCard
          bgColor="tw-bg-red-500"
          count={data?.rejectedRequests || 0}
          title="Отклоненные заявки"
        />

        <StatisticCard
          bgColor="tw-bg-green-400"
          count={data?.doneRequests || 0}
          title="Завершенные заявки"
        />
      </div>
    </div>
  );
}

export default MainStatistic;
