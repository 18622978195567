import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  phone: any;
  email: any;
}

export default function Footer({ phone, email, ...props }: Props) {
  const { t } = useTranslation();
  return (
    <footer
      {...props}
      className={`${props.className} tw-flex tw-justify-center tw-items-center md:tw-py-[72px] tw-py-5 tw-bg-[#EFF5F9]`}
    >
      <div className="tw-mx-auto tw-max-w-[1282px] tw-w-full tw-flex tw-justify-center md:tw-px-5">
        <div className="tw-flex tw-w-full tw-items-start tw-justify-between tw-gap-5 tw-flex-col md:tw-flex-row">
          <div className="tw-flex md:tw-w-[42%] tw-items-center tw-justify-center tw-gap-4 tw-w-[90%] tw-mx-auto md:tw-mx-0 tw-flex-col md:tw-flex-row">
            <img
              src="/images/img_logo.svg"
              alt="Logo"
              className="tw-h-[60px] tw-w-[60px] tw-object-contain"
            />
            <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-gap-y-1.5 tw-self-stretch md:tw-self-auto">
              <p className="tw-font-opensans tw-text-[14px] tw-font-semibold tw-uppercase tw-text-[#252525] tw-text-center md:tw-text-left">
              {t('HomePage.publicServicesPortal')}

              </p>
              <p className="tw-font-opensans tw-text-[14px] tw-text-center md:tw-text-start tw-font-medium tw-leading-none tw-tracking-[1.00px] tw-text-[#252525B2]">
                <>
                {t('HomePage.ministryOfFinance')}

                  <br />
                  {t('HomePage.republicOfTajikistan')}

                </>
              </p>
            </div>
          </div>
          <div className="tw-flex md:tw-w-[52%] tw-items-center md:tw-items-start tw-justify-between tw-gap-7 tw-self-center tw-w-full tw-flex-col md:tw-flex-row">
            <div className="tw-flex md:tw-w-[60%] tw-items-start tw-justify-between tw-gap-5 tw-w-[90%] tw-flex-row">
              <div className="tw-flex md:tw-w-[64%] tw-flex-col tw-items-start tw-gap-2 tw-self-center tw-w-[50%]">
                <p className="tw-font-inter tw-text-[14px] tw-font-semibold tw-text-[#66727F]">
                {t('HomePage.information')}

                </p>
                <ul className="tw-flex md:tw-w-[76%] tw-flex-col tw-items-start tw-gap-2 tw-w-full">
                  <li>
                    <a href="#" className="tw-leading-[17px]">
                      <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-text-[#66727F]">
                        <>
                        {t('HomePage.informationMission')}
                        <br />
                        {t('HomePage.system')}

                        </>
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href="/instruction">
                      <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-text-[#66727F]">
                      {t('HomePage.instructions')}

                      </p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-text-[#66727F]">
                      {t('HomePage.privacyPolicy')}

                      </p>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                <li>
                  <a href="Кабинет" target="_blank" rel="noreferrer">
                    <p className="tw-font-inter tw-text-[14px] tw-font-semibold tw-text-[#66727F]">
                    {t('HomePage.cabinet')}

                    </p>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-text-[#66727F]">
                    {t('HomePage.forIndividuals')}

                    </p>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-text-[#66727F]">
                    {t('HomePage.forLegalEntities')}

                    </p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tw-flex md:tw-w-[28%] tw-flex-col tw-items-start tw-justify-center tw-gap-2 tw-w-[90%]">
              <p className="tw-font-inter tw-text-[14px] tw-font-semibold tw-text-[#66727F]">
              {t('HomePage.contactInformation')}

              </p>
              <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-leading-[17px] tw-text-[#66727F]">
                <span>
                  <>
                  {t('HomePage.hotlinePhone')}

                    <br />
                  </>
                </span>
                {phone?.length > 0 &&
                  phone.map((item: string) => (
                    <a
                      className="tw-font-inter tw-text-[14px] tw-font-medium tw-tracking-[0.28px] tw-text-gray-900"
                      href={`tel:${item}`}
                      key={item}
                    >
                      {item}
                    </a>
                  ))}
              </p>
              <p className="tw-font-inter tw-text-[13px] tw-font-normal tw-leading-[17px] tw-text-[#66727F]">
                <>
                {t('HomePage.emailAddress')}
                <br />
                  {email?.length > 0 &&
                    email.map((item: string) => (
                      <a
                        className="tw-font-inter tw-text-[14px] tw-font-normal tw-text-gray-900"
                        href={`mailto:${item}`}
                        key={item}
                      >
                        {item}
                      </a>
                    ))}
                </>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
