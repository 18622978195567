import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {t} from 'i18next';
import { format } from 'date-fns';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const titles = [
  '',
  t("HomePage.file"),
  t('HomePage.documentType')
,
  // 'Дата создания',
  // 'Создал',
];

export const typeDoc: any = {
  1: 'Лицевая сторона паспорта',
  2: 'Обратнаяя сторона паспорта',
  3: 'Загран паспорт',
  4: 'Водительское удостоверение',
  5: 'СНИЛС',
  6: 'ИНН',
  7: 'Сертификат Организации',
  8: 'ИНН Организации',
}

export const headerTitles: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Название',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => <Box></Box>,
  },
  {
    field: 'template',
    headerName: 'Тип документа',
    flex: 3,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => <Chip label={params.value} />,
  },
  // {
  //   field: 'createdAt',
  //   headerName: 'Дата создания',
  //   flex: 2,
  //   sortable: false,
  //   filterable: false,
  //   valueFormatter: (params) => {
  //     return format(new Date(params.value), 'dd-MM-yyyy hh:mm');
  //   },
  // },
  // {
  //   field: 'organisation',
  //   headerName: 'Создал',
  //   flex: 2,
  //   sortable: false,
  //   filterable: false,
  //   valueFormatter: (params) => {
  //     return params.value?.value;
  //   },
  // },
];
