import React from 'react';

const SixthText = () => {
  return (
    <div>
      <p>
        Для того чтобы подать заявку на получение услуги, необходимо выполнить
        несколько шагов, описанных ниже.
      </p>
      <p className="tw-mt-[10px]">
        Шаги для подачи заявки на получение услуги:
      </p>
      <ul className="tw-list-decimal tw-pl-[30px]">
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Ознакомление с услугой:</span>{' '}
          Перейдите на страницу с описанием нужной вам услуги. Ознакомьтесь с
          требованиями и условиями получения услуги, чтобы понять, что именно
          нужно предоставить для оформления заявки.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">
            Нажмите на кнопку «Получить услугу»:
          </span>{' '}
          После того как вы ознакомились с услугой, найдите и нажмите на кнопку
          "Получить услугу". Это перенаправит вас на страницу для заполнения
          заявки.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Заполнение формы заявки:</span>
          На странице оформления заявки вам нужно будет заполнить все
          необходимые поля, связанные с выбранной услугой. Это могут быть данные
          о вас, информация о компании (если это юридическое лицо), а также
          другие обязательные данные, которые зависят от типа услуги.
        </li>
        <li>
          <span className="tw-font-bold">Загрузка необходимых документов:</span>
          В процессе оформления заявки вам может потребоваться загрузить
          дополнительные документы, подтверждающие ваше право на получение
          услуги. Убедитесь, что документы загружены в нужном формате и
          соответствуют требованиям (например, сканы паспорта, ИНН и другие).
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Сохранение и отправка заявки:</span>
          После того как вы заполните все поля формы и загрузите документы,
          проверьте все введенные данные. Если все правильно, нажмите кнопку
          "Отправить". Это отправит вашу заявку на рассмотрение уполномоченным
          лицам.
        </li>
        <li className="tw-mt-[10px]">
          <span className="tw-font-bold">Рассмотрение заявки:</span>
          После отправки заявки она будет передана на рассмотрение ответственным
          лицам. В зависимости от типа услуги и требований, они могут связаться
          с вами для уточнения дополнительных деталей или для подтверждения
          предоставленных документов.
        </li>
      </ul>
      <p className="tw-mt-[10px]">Важные моменты:</p>
      <ul className="tw-list-disc tw-pl-[30px] tw-mt-[10px]">
        <li>
          Убедитесь, что все поля формы заполнены корректно и все необходимые
          документы загружены.
        </li>
        <li>
           После отправки заявки вы получите уведомление о том, что ваша заявка
          поступила на рассмотрение. В случае необходимости вас могут попросить
          предоставить дополнительные документы или информацию.
        </li>
      </ul>
    </div>
  );
};

export default SixthText;
