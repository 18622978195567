import React from 'react';

function StatisticCard({
  bgColor,
  title,
  count,
}: {
  bgColor: string;
  title: string;
  count: number;
}) {
  return (
    <div
      className={`tw-w-full tw-h-[170px] tw-rounded-3xl tw-font-roboto ${bgColor} hover:tw-drop-shadow-xl tw-transition-all tw-ease-linear tw-duration-100`}
    >
      <div className="tw-w-[85%] tw-h-[90%] tw-mx-auto tw-justify-center tw-flex tw-flex-col tw-items-start tw-space-y-9">
        <h3 className="tw-text-white tw-text-[19px] tw-font-semibold">
          {title}
        </h3>
        <p className="tw-text-white tw-text-[26px] tw-font-semibold">{count}</p>
      </div>
    </div>
  );
}

export default StatisticCard;
